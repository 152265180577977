import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import "./benefits.scss"

import IconCryptoExchange from "../../images/icon-cripto-exchange.svg"
import IconMonitor from "../../images/icon-credit-monitor.svg"
import IconCryptoSecurity from "../../images/icon-cripto-security.svg"

const Benefits = ({ intl }) => {
  const items = [
    {
      title: intl.formatMessage({ id: "benefits-loan-title" }),
      description: intl.formatMessage({ id: "benefits-loan-description" }),
      icon: <IconCryptoExchange />,
      to: "#loan-description",
    },
    {
      title: intl.formatMessage({ id: "benefits-monitor-title" }),
      description: intl.formatMessage({ id: "benefits-monitor-description" }),
      icon: <IconMonitor />,
      to: "#monitoring-description",
    },
    {
      title: intl.formatMessage({ id: "benefits-security-title" }),
      description: intl.formatMessage({ id: "benefits-security-description" }),
      icon: <IconCryptoSecurity />,
    },
  ]

  const renderLink = (item, children) =>
    item.to ? (
      <AnchorLink to={item.to} title={item.title}>
        {children}
      </AnchorLink>
    ) : (
      <Link to={item.link}>{children}</Link>
    )
  return (
    <div className="benefits">
      {items.map((item, index) => (
        <div key={index} className="item">
          {renderLink(
            item,
            <div className="row">
              {item.icon}
              <div>
                <h3 className="dark-grey-color">{item.title}</h3>
                <p className="dark-grey-color">{item.description}</p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default injectIntl(Benefits)
