import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoanQuickAccess from "../components/loan-quick-access"
import Benefits from "../components/benefits"
import LoanDescription from "../components/loan-description"
// import ToolsDescription from "../components/tools-description"
// import CustomerChat from "../components/customer-chat"
// <CustomerChat />

// <ToolsDescription />

const IndexPage = ({ location }) => (
  <Layout prefooter="1" menuSelector={location?.state?.menuSelector || 1}>
    <SEO title="Home" />
    <LoanQuickAccess />
    <Benefits />
    <LoanDescription />

  </Layout>
)

export default IndexPage
