import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Button } from "antd"
import { ArrowRightOutlined } from "@ant-design/icons"

import CheckList from "../checklist"
import LphantBig from "../../images/icon-lphant-big.svg"

import "./loan-description.scss"

const LoanDescription = ({ intl }) => {
  const items = [
    {
      description: intl.formatMessage({ id: "loan-description-amount" }),
    },
    {
      description: intl.formatMessage({ id: "loan-description-tax-rate" }),
    },
    {
      description: intl.formatMessage({ id: "loan-description-deadline" }),
    },
    {
      description: intl.formatMessage({ id: "loan-description-history" }),
    },
    {
      description: intl.formatMessage({ id: "loan-description-receipts" }),
    },
    {
      description: intl.formatMessage({ id: "loan-description-security" }),
    },
  ]
  return (
    <div id="loan-description" className="loan-description">
      <div className="lphant-big">
        <LphantBig />
      </div>
      <div className="content-wrapper">
        <div className="column-max-size">
          <CheckList
            title={intl.formatMessage({ id: "loan-description-title" })}
            message={intl.formatMessage({ id: "loan-description-message" })}
            items={items}
          />
          <div className="actions">
            <Link to="/calculadora/" state={{ menuSelector: 2 }}>
              <Button type="primary">
                {intl.formatMessage({ id: "loan-description-calculator" })}
              </Button>
            </Link>
            <Button type="link">
              <h5 className="primary-color">
                {intl.formatMessage({ id: "loan-description-sign-up" })}
                <ArrowRightOutlined />{" "}
              </h5>
            </Button>
          </div>
          {/* <p className='conditions'>{ intl.formatMessage({ id: "loan-description-conditions" }) }</p> */}
        </div>
      </div>
    </div>
  )
}

export default injectIntl(LoanDescription)
