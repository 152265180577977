import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Check from "../check"

import "./checklist.scss"

const CheckList = ({ items, title, message }) => {
  return (
    <div className="checklist">
      <h1 className="checklist-title">{title}</h1>
      <h3 className="checklist-subtitle">{message}</h3>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <Check />
            <span>{item.description}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default injectIntl(CheckList)
