import React, { useState } from "react"
import ReactPlayer from "react-player"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { Input } from "antd"

import Bgphoto from "../../images/bg-photo.svg"

import "./loan-quick-access.scss"

const LoanQuickAccess = ({ children, intl }) => {
  const onSearch = loanAmount => {
    navigate("/calculadora", {
      state: {
        loanAmount,
        menuSelector: 2,
      },
    })
  }
  // const lphantVideo = "https://test-videos.co.uk/vids/bigbuckbunny/mp4/av1/1080/Big_Buck_Bunny_1080_10s_5MB.mp4"
  const lphantVideo =
    "https://l-phant-branding-assets.s3.amazonaws.com/l-phant-short.mp4"

  const [playing, setPlaying] = useState(false)
  return (
    <div className="loan-quick-access">
      <div className="bg-photo fixed-right">
        <div className="player-wrapper">
          <ReactPlayer
            width="1040px"
            height="585px"
            playing={playing}
            loop
            muted
            fallback={<Bgphoto />}
            onReady={() => {
              setPlaying(true)
              console.log("loaded and ready to play video")
            }}
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true,
                  loop: true,
                },
              },
            }}
            url={lphantVideo}
          />
        </div>
      </div>
      <div className="fixed-left ">
        <h1 className="loan-info title-1">
          {intl.formatMessage({ id: "loan-quick-access-title" })}
        </h1>
        <h1 className="loan-info title-2">
          {intl.formatMessage({ id: "loan-quick-access-title-loan" })}
        </h1>
        <h3 className="loan-info content-1">
          {intl.formatMessage({ id: "loan-quick-access-message-1" })}
        </h3>
        <h5 className="loan-info content-2">
          {intl.formatMessage({ id: "loan-quick-access-message-2" })}
        </h5>
        <Input.Search
          placeholder={intl.formatMessage({
            id: "loan-quick-access-placeholder",
          })}
          allowClear
          enterButton={intl.formatMessage({ id: "loan-quick-access-button" })}
          size="large"
          onSearch={onSearch}
        />
        {children}
      </div>
    </div>
  )
}

export default injectIntl(LoanQuickAccess)
